"use client";
// context/DialogContext.tsx
import { createContext, useContext, useState, useCallback } from "react";

import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";

interface DialogContextType {
  openDialog: (options: { content: React.ReactNode; title?: string; description?: string }) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export function DialogProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<{
    content: React.ReactNode;
    title?: string;
    description?: string;
  } | null>(null);

  const openDialog = useCallback((options: { content: React.ReactNode; title?: string; description?: string }) => {
    setDialogContent(options);
    setIsOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
    setTimeout(() => setDialogContent(null), 300);
  }, []);

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="bg-white text-black sm:max-w-2xl">
          {dialogContent?.title && (
            <DialogHeader>
              <DialogTitle>{dialogContent.title}</DialogTitle>
              {dialogContent.description && <DialogDescription>{dialogContent.description}</DialogDescription>}
            </DialogHeader>
          )}
          {dialogContent?.content}
        </DialogContent>
      </Dialog>
    </DialogContext.Provider>
  );
}

export function useDialog() {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
}
