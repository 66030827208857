"use client";

import { X } from "lucide-react";
import Image from "next/image";
import { usePathname } from "next/navigation";
import type React from "react";
import { useState, useEffect, useRef } from "react";

import IconWhatsAppGreen from "../Icons/IconWhatsappGreen";
import whatsAppChatBackground from "@/public/assets/images/whatsapp-chat-bg.jpg";
import whatsAppAvatar from "@/public/assets/images/whatsapp-icon.jpeg";

interface WhatsAppWidgetProps {}

const WhatsAppWidget: React.FC<WhatsAppWidgetProps> = ({}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const chatBoxRef = useRef<HTMLDivElement>(null);
  const pathname = usePathname();

  useEffect(() => {
    if (chatBoxRef.current) {
      const chatBox = chatBoxRef.current;
      if (isOpen) {
        // Set initial position for animation
        chatBox.style.opacity = "1";
        chatBox.style.transform = "translateY(0)";
      } else {
        // Fade out
        chatBox.style.opacity = "0";
        chatBox.style.transform = "translateY(20px)";
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (pathname.startsWith("/buy-used-cars/vehicle")) {
      const message = `Hi Alba Cars,
I'm interested in this car:\n
${window.location.href}\n
Could you assist me?`;
      setMessage(message);
    } else {
      const message = `Hi Alba Cars,
I'm interested in a car and need some help.
Could you assist me?`;
      setMessage(message);
    }
  }, [pathname]);

  const whatsappLink = `https://wa.me/${97143772503}?text=${encodeURIComponent(message)}`;

  const handleToggleChat = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="fixed bottom-4 right-2 z-50 flex flex-col items-end ixs:right-4">
      {/* Chat Box - Positioned above the button */}
      {
        <div
          ref={chatBoxRef}
          className={`absolute right-0 mb-2 h-[310px] w-[300px] overflow-hidden rounded-2xl bg-white shadow-xl transition-all duration-300 ease-in-out ixs:w-[360px] ${
            isOpen ? "pointer-events-auto" : "pointer-events-none"
          }`}
          style={{
            bottom: "65px",
            opacity: "0",
            transform: "translateY(20px)",
          }}
        >
          {/* Header */}
          <div className="flex items-center justify-between bg-[#00826a] px-4 py-5 text-white">
            <div className="relative flex items-center gap-3">
              <span className="absolute bottom-0 left-10 flex h-2.5 w-2.5 rounded-full border border-[#00826a] bg-[#25D366]"></span>
              <Image
                src={whatsAppAvatar}
                width={52}
                height={52}
                alt="WhatsApp Contact"
                className="rounded-full"
                placeholder="blur"
              />
              <div className="inter">
                <h3 className="text-base font-semibold">Alba Cars</h3>
                <p className="text-sm text-white opacity-80">Online</p>
              </div>
            </div>
            <button
              onClick={handleToggleChat}
              className="self-start text-white hover:text-gray-200"
              aria-label="Close chat"
            >
              <X size={18} />
            </button>
          </div>

          {/* Chat Content */}
          <div
            className="relative p-4"
            style={{
              backgroundImage: `url(${whatsAppChatBackground.src})`,
              backgroundSize: "cover",
              backgroundRepeat: "repeat",
              minHeight: "220px",
            }}
          >
            <div className="inter mb-2 text-center text-[0.65rem] text-gray-400">
              {new Date().toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: false })}
            </div>

            {/* Message bubble with arrow */}
            <div className="inter relative ms-2 inline-block max-w-[80%] rounded-2xl bg-white p-3 shadow-sm">
              {/* Left arrow for the message bubble */}
              <div
                className="absolute -left-2 top-0"
                style={{
                  content: "''",
                  position: "absolute",
                  width: 0,
                  height: 0,
                  borderTop: "15px solid white",
                  borderLeft: "15px solid transparent",
                  borderRight: "15px solid transparent",
                  top: "0",
                  left: "-15px",
                }}
              ></div>
              <div
                className="absolute -left-2 top-0"
                style={{
                  content: "''",
                  position: "absolute",
                  width: 0,
                  height: 0,
                  borderTop: "17px solid rgba(0,0,0,0.1)",
                  borderLeft: "16px solid transparent",
                  borderRight: "16px solid transparent",
                  top: "-1px",
                  left: "-17px",
                  zIndex: -1,
                }}
              ></div>
              <p className="mb-2 text-sm">Hi there 👋</p>
              <p className="text-sm">How can I help you?</p>
            </div>

            {/* Floating WhatsApp Button */}
            <div className="inter absolute bottom-4 left-0 right-0 flex justify-center">
              <a
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleToggleChat}
                className="flex items-center justify-center gap-2 rounded-full bg-[#25D366] px-6 py-2 text-sm font-semibold text-white shadow-md transition hover:bg-green-500"
              >
                <IconWhatsAppGreen size={26} />
                Chat on WhatsApp
              </a>
            </div>
          </div>
        </div>
      }

      {/* Floating WhatsApp Button */}
      <button
        onClick={handleToggleChat}
        className="relative rounded-full bg-[#25D366] p-3 text-white shadow-lg transition-all duration-300 hover:cursor-pointer hover:bg-[#33e474]"
        aria-label="Open WhatsApp chat"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            handleToggleChat();
          }
        }}
      >
        <IconWhatsAppGreen size={40} />
        <span className="absolute right-0 top-2 flex h-2.5 w-2.5 items-center justify-center rounded-full bg-red-500 text-xs text-white"></span>
      </button>
    </div>
  );
};

export default WhatsAppWidget;
