/* eslint-disable unused-imports/no-unused-vars */
"use client";

/* eslint-disable jsx-a11y/alt-text */
import Image, { ImageProps, StaticImageData } from "next/image";

type ImageLayoutType =
  | "full" // Full width
  | "grid-2" // Two columns
  | "grid-3" // Three columns
  | "grid-4" // Four columns
  | "sidebar" // Sidebar fixed width
  | "custom"; // Custom sizes

interface OptimizedImageProps extends Omit<ImageProps, "src" | "sizes" | "height"> {
  src: string | StaticImageData;
  imageLayout?: ImageLayoutType;
  sizes?: string;
  maxWidth?: number;
  height?: number;
}

interface CloudFrontLoaderProps {
  src: string;
  width?: number;
  quality?: number;
}

const SIZES_MAP = {
  full: "100vw",
  "grid-2": "(max-width: 768px) 100vw, 50vw",
  "grid-3": "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw",
  "grid-4": "(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 25vw",
  sidebar: "(max-width: 768px) 100vw, 300px",
} as const;

const createCloudFrontLoader = (height?: number, maxWidth?: number) => {
  return ({ src, width, quality = 75 }: CloudFrontLoaderProps) => {
    if (src.startsWith("data:") || src.startsWith("blob:") || src.startsWith("/_next/")) {
      return `${src}?w=${width}&q=${quality}`;
    }

    try {
      const url = new URL(src);

      const finalWidth = width && maxWidth ? Math.min(width, maxWidth) : width;

      if (finalWidth && !url.searchParams.has("width")) {
        url.searchParams.set("width", finalWidth.toString());
      }

      // Use the height from props
      if (height && !url.searchParams.has("height")) {
        url.searchParams.set("height", height.toString());
      }

      if (quality !== 75) {
        url.searchParams.set("quality", quality.toString());
      }

      url.searchParams.set("format", "webp");

      return url.toString();
    } catch (error) {
      console.error("Invalid URL:", src);
      return src;
    }
  };
};

const OptimizedImage: React.FC<OptimizedImageProps> = ({ src, imageLayout = "full", maxWidth, ...props }) => {
  const sizes = imageLayout === "custom" ? props.sizes : SIZES_MAP[imageLayout];
  const loader = createCloudFrontLoader(props.height ? Number(props.height) : undefined, maxWidth);

  // If height is not provided, use fill mode with appropriate styling
  if (!props.height) {
    const { width, ...rest } = props;
    return <Image src={src} sizes={sizes} loader={loader} fill style={{ objectFit: "cover" }} {...rest} />;
  }

  return <Image src={src} sizes={sizes} loader={loader} {...props} />;
  // return <Image src={src} sizes={sizes} {...props} />;
};

export default OptimizedImage;
