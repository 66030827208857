"use client";

import { formatImageUrl } from "@alba-cars/common-modules";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { LogOut, User } from "lucide-react";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";

import AuthDialog from "@/components/auth/AuthDialog";
import OptimizedImage from "@/components/common/images/OptimizedImage";
import Icon from "@/components/Icons/Icon";
import IconHeart from "@/components/Icons/IconHeart";
import IconUser from "@/components/Icons/IconUser";
import { useAuth } from "@/contexts/AuthContext";
import { useDialog } from "@/contexts/DialogContext";
import { cn } from "@/lib/utils";

const NavUserActions = () => {
  const { openDialog } = useDialog();
  const { user, isAuthenticated, profile, isProfileLoading, logout } = useAuth();
  const pathname = usePathname();
  const router = useRouter();

  const isProfilePage = pathname === "/my-profile";
  const isLoginPage = pathname === "/login";

  const profilePicture = profile?.photo ? formatImageUrl(profile.photo) : "";

  const handleUserIconClick = () => {
    if (!user && !isLoginPage) {
      openDialog({
        content: <AuthDialog />,
      });
    }
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <div className="hidden space-x-2 xl:flex">
      <Link
        href={`/favorites?source=nav${isAuthenticated ? "&authenticated=true" : ""}`}
        className="flex items-center rounded-20 bg-blue transition-all duration-300 hover:cursor-pointer hover:bg-[#345A8C33]"
      >
        <span className="sr-only">Favorites</span>
        <Icon icon={<IconHeart fill="#FF3448" />} width={14} />
      </Link>
      {user ? (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild>
            <div
              className={cn(
                "flex max-h-14 max-w-14 items-center rounded-20 bg-blue transition-all duration-300 hover:cursor-pointer hover:bg-[#345A8C33]",
                !profilePicture && "p-2",
              )}
              aria-label="User menu"
              aria-controls="user-menu"
              aria-haspopup="menu"
              aria-expanded="false"
            >
              <span className="sr-only">Profile</span>
              {isProfileLoading ? (
                <div className="h-14 w-14 animate-pulse rounded-full bg-gray-200" />
              ) : profilePicture ? (
                <OptimizedImage
                  src={profilePicture}
                  alt={`${user.name}'s profile image`}
                  className="h-14 w-14 rounded-full object-cover object-center p-2"
                  imageLayout="custom"
                  sizes="56px"
                  width={56}
                  height={64}
                />
              ) : (
                <div className="inter flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-primary p-2 text-white">
                  {user.name?.charAt(0).toUpperCase()}
                </div>
              )}
            </div>
          </DropdownMenu.Trigger>
          <DropdownMenu.Portal>
            <DropdownMenu.Content
              className="data-[side=top]:animate-slideUp data-[side=bottom]:animate-slideDown z-[8000] min-w-[200px] rounded-2xl bg-white shadow-sm"
              sideOffset={8}
              avoidCollisions
              role="menu"
              aria-label="User options"
              aria-orientation="vertical"
            >
              {!isProfilePage ? (
                <>
                  <DropdownMenu.Item
                    className="hover:gray-100 flex items-center space-x-4 rounded-2xl border-none p-4 outline-none transition-all duration-300 hover:cursor-pointer focus:bg-gray-100 focus:outline-none"
                    role="menuitem"
                    tabIndex={0}
                    onSelect={() => router.push("/my-profile")}
                  >
                    <User className="h-4 w-4" />
                    <span className="inter font-semibold text-black">My Profile</span>
                  </DropdownMenu.Item>
                  <hr className="mx-4" aria-orientation="horizontal" />
                </>
              ) : null}
              <DropdownMenu.Item
                className="hover:gray-100 flex items-center space-x-4 rounded-2xl border-none p-4 outline-none transition-all duration-300 hover:cursor-pointer focus:bg-gray-100 focus:outline-none"
                role="menuitem"
                tabIndex={0}
                onSelect={handleLogout}
              >
                <LogOut className="h-4 w-4" />
                <span className="inter font-semibold text-black">Logout</span>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Portal>
        </DropdownMenu.Root>
      ) : (
        <div
          onClick={handleUserIconClick}
          className="flex items-center rounded-20 bg-blue transition-all duration-300 hover:cursor-pointer hover:bg-[#345A8C33]"
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleUserIconClick();
            }
          }}
        >
          <span className="sr-only">Profile</span>
          <Icon icon={<IconUser />} width={14} height={14} />
        </div>
      )}
    </div>
  );
};

export default NavUserActions;
