"use client";

import React, { useEffect } from "react";
import IconPlus from "../Icons/IconPlus";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  size?: "small" | "medium" | "large" | "full";
  className?: string;
  closeButtonClassName?: string;
  childrenClassName?: string;
}

const sizeClasses = {
  small: "max-w-md",
  medium: "max-w-3xl",
  large: "max-w-7xl",
  full: "max-full h-full",
};

const defaultCloseButtonClasses = "absolute right-4 top-4 cursor-pointer text-black hover:opacity-70";

const Modal = ({
  isOpen,
  onClose,
  children,
  size = "large",
  className = "",
  closeButtonClassName = defaultCloseButtonClasses,
  childrenClassName = "",
}: ModalProps) => {
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    document.addEventListener("keydown", handleEscKey);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-100000 flex items-center justify-center bg-black/80 backdrop-blur "
      onClick={onClose}
      onKeyDown={(e) => e.key === "Escape" && onClose()}
      tabIndex={0}
      role="button"
      aria-label="Close modal overlay"
    >
      <div
        className={`relative w-full rounded-lg bg-white p-4 shadow-lg ${sizeClasses[size]} ${className}`}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.key === "Enter" && e.stopPropagation()}
        tabIndex={0}
        role="button"
        aria-label="Modal content"
      >
        <button aria-label="Close modal" onClick={onClose} className={closeButtonClassName}>
        <IconPlus className="rotate-45" />
        </button>
        <div className={`max-h-[90vh] overflow-auto ${childrenClassName}`}>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
