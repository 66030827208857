"use client";

import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

import logo from "@/public/assets/images/albacars-logo.png";
import { navigationLinks } from "@/utils/constants";
import Container from "../layout/Container";
import NavMobileMenu from "./components/NavMobileMenu";
import NavUserActions from "./components/NavUserActions";
import DesktopNavLinksWrapper from "./DesktopNavLinksWrapper";

const Navbar: React.FC = () => {
  const pathname = usePathname();

  // Define which paths should have sticky navbar
  const stickyPaths = ["/"];
  const isSticky = stickyPaths.includes(pathname);

  return (
    <div className={`bg-white py-5 ${isSticky ? "sticky top-0 z-50" : ""}`}>
      <Container>
        <header className="header">
          <nav className="flex items-center justify-between text-[#000]">
            <div className="relative min-w-[140px] lg:min-w-[130px] xl:min-w-[140px] 2xl:min-w-[180px]">
              <Link href="/">
                <Image
                  src={logo}
                  alt="logo"
                  priority
                  quality={100}
                  width={0}
                  height={45}
                  className="object-cover object-center"
                  sizes="(max-width: 640px) 120px,
                  (max-width: 768px) 140px,
                  (max-width: 1024px) 130px,
                  (max-width: 1280px) 100px,
                  (max-width: 1536px) 140px,
                 180px"
                />
              </Link>
            </div>

            {/* Desktop Menu */}
            <DesktopNavLinksWrapper navigationLinks={navigationLinks} />

            {/* Icons and Menu Button */}
            <div className="flex">
              {/* <LanguageSwitcher /> */}

              <NavUserActions />

              <NavMobileMenu />
            </div>
          </nav>
        </header>
      </Container>
    </div>
  );
};

export default Navbar;
