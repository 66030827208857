"use client";

import { timeAsSeconds } from "@alba-cars/common-modules";
import { isServer, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SessionProvider } from "next-auth/react";

import { AuthProvider } from "@/contexts/AuthContext";
import { DialogProvider } from "@/contexts/DialogContext";
import { FavoritesProvider } from "@/contexts/FavoritesContext";

// const queryClient = new QueryClient();
function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5, // 5 minutes
      },
    },
  });
}
let browserQueryClient: QueryClient | undefined = undefined;
function getQueryClient() {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}

export default function Providers({ children, isDevMode }: { children: React.ReactNode; isDevMode: boolean }) {
  const queryClient = getQueryClient();

  //* added refetchInterval & refetchOnWindowFocus = false to prevent unnecessary frequent refetching of auth session information
  return (
    <SessionProvider refetchOnWindowFocus={false} refetchInterval={timeAsSeconds({ minutes: 30 })}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <FavoritesProvider>
            <DialogProvider>
              {children}
              {isDevMode && <ReactQueryDevtools initialIsOpen={false} />}
            </DialogProvider>
          </FavoritesProvider>
        </AuthProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}
