import type React from "react";

interface WhatsAppIconProps {
  size?: number;
  color?: string;
}

const IconWhatsAppGreen: React.FC<WhatsAppIconProps> = ({ size = 24, color = "white" }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 400.000000 400.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)" fill={color} stroke="none">
        <path
          d="M1860 3480 c-375 -46 -726 -239 -955 -523 -218 -270 -325 -575 -325
-924 0 -231 45 -424 151 -645 l52 -106 -132 -387 c-102 -302 -127 -385 -113
-380 9 3 190 61 402 129 l385 124 65 -33 c288 -144 667 -184 985 -104 231 59
431 163 612 320 164 141 316 362 396 574 67 176 105 422 92 595 -28 375 -166
681 -425 941 -212 213 -448 339 -744 400 -116 23 -332 33 -446 19z m351 -250
c104 -15 235 -57 345 -110 311 -151 548 -442 642 -790 24 -87 26 -112 26 -290
0 -178 -2 -203 -26 -291 -119 -441 -446 -769 -876 -880 -279 -72 -566 -39
-837 96 l-130 65 -215 -70 c-118 -38 -221 -70 -227 -70 -8 0 15 82 63 220 l75
220 -29 42 c-74 108 -147 283 -178 428 -23 106 -26 352 -5 455 93 472 449 847
908 956 152 36 296 42 464 19z"
        />
        <path
          d="M1482 2734 c-78 -40 -155 -163 -174 -278 -23 -140 24 -284 163 -491
171 -255 335 -412 538 -515 89 -45 275 -116 341 -130 115 -25 277 26 358 114
44 47 60 89 69 177 l6 57 -74 41 c-41 22 -120 64 -176 93 -124 63 -125 62
-212 -40 -97 -113 -93 -111 -198 -59 -108 55 -192 117 -271 202 -90 95 -172
221 -172 263 0 10 16 34 36 53 43 41 94 117 94 138 0 22 -115 327 -135 357 -8
13 -25 24 -38 24 -12 0 -44 3 -72 5 -36 4 -59 1 -83 -11z"
        />
      </g>
    </svg>
  );
};

export default IconWhatsAppGreen;
